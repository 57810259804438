
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';

import AddressComponent from '@/components/address/address.component.vue';
import { SellingPoint } from '../../entities/selling-point.entity';
import CompaniesService from '../../../Companies/services/companies.service';
import sellingPointsService from '../../services/selling-points.service';
import { AddressViaCepInterface } from '../../../../../../components/address/interfaces/address-via-cep.interface';
import { Address } from '../../../../../../components/address/entities/address.entity';
import { Company } from '../../../Companies/entities/company.entity';
import userService from '../../../Users/services/user.service';
import { User } from '../../../Users/entities/user.entity';

@Component({
  components: {
    AlertError,
    DatePicker,
    AddressComponent,
    DeleteDialog,
  },
})
export default class SellingPointCreateComponent extends Vue {
  public $refs: any;

  private sellingpoint: SellingPoint = new SellingPoint();

  private companies: Company[] = [];

  private showDialogDelete: boolean = false;

  private errors: any[] = [];

  private users: User[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get showAddressComponent() {
    if (!this.isEdit) {
      return true;
    }
    return !!this.sellingpoint.address.cep;
  }

  private async getSellingPointById() {
    this.$loader.open();
    const { id } = this.$route.params;
    const sellingpoint = await sellingPointsService.getSellingPointById(id);
    this.sellingpoint = new SellingPoint(sellingpoint);
    this.$loader.close();
  }

  private async getAllUsers() {
    if (!this.sellingpoint.companyId) return;
    const users = await userService.getAllUsers({
      params: {
        company_id: this.sellingpoint.companyId,
      },
    });
    this.users = users.map((it: any) => new User(it));
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private setShowDialogDelete(sellingpoint: SellingPoint) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteItem() {
    this.sellingpoint
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Ponto de venda excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async save() {
    this.sellingpoint
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Ponto de Venda cadastrada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'selling-point-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar ponto de venda',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.sellingpoint
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Ponto de venda alterada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'selling-point-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async setCompanyId(companyId: string) {
    this.sellingpoint.companyId = companyId;
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
  }

  private async setAddressByCep(address: AddressViaCepInterface) {
    const fullAddress = { ...address, number: this.sellingpoint.address.number };
    this.sellingpoint.address = new Address(fullAddress);
  }

  private async goToList() {
    this.$router.push({
      name: 'selling-point-list',
    });
  }

  private created() {
    this.getCompanies();
    if (this.isEdit) {
      this.getSellingPointById();
    }
    this.getAllUsers();
  }
}
